import { createAction, props } from '@ngrx/store';
import { SetTopBox } from '../core/models/set-top-box.model';

export const stbUpdated = createAction(
  '[STB][WS] STB Updated',
  props<{ setTopBox: SetTopBox }>()
);

export const avInputUpdated = createAction('[STB][WS] AV Input Updated');

export const reload = createAction('[STB][WS] STB Client Reload');

export const reset = createAction('[STB][WS] Reset');

export const standby = createAction('[STB][WS] Standby');

export const setPowerWarm = createAction('[STB][WS] Power - Warm');

export const setPowerNormal = createAction('[STB][WS] Power - Normal');

export const reboot = createAction('[STB][WS] Reboot');

export const stbReportMessengerVersion = createAction(
  '[STB][WS] Report Messenger Version'
);

export const setCurrentInput = createAction(
  '[STB] Set Current Input',
  props<{ typ: number; index: number }>()
);
export const getCpuUsage = createAction('[STB] Get CPU Usage');

export const getCpuUsageSuccess = createAction(
  '[STB] Get CPU Usage Success',
  props<{ percentage: number }>()
);
export const getCpuUsageFailure = createAction(
  '[STB] Get CPU Usage Failure',
  props<{ error: any }>()
);

export const getMemoryUsage = createAction('[STB] Get Memory Usage');

export const getMemoryUsageSuccess = createAction(
  '[STB] Get Memory Usage Success',
  props<{ percentage: number }>()
);
export const getMemoryUsageFailure = createAction(
  '[STB] Get Memory Usage Failure',
  props<{ error: any }>()
);

export const patchSetTopBox = createAction(
  '[STB] Patch Set Top Box',
  props<any>()
);

export const patchSetTopBoxSuccess = createAction(
  '[STB] Patch Set Top Box Success',
  props<{ setTopBox: SetTopBox }>()
);

export const patchSetTopBoxFailure = createAction(
  '[STB] Patch Set Top Box Failure',
  props<{ error: any }>()
);

export const getFirmwareVersion = createAction('[STB] Get Firmware Version');

export const getFirmwareVersionSuccess = createAction(
  '[STB] Get Firmware Version Success',
  props<{ version: string }>()
);

export const getFirmwareVersionFailure = createAction(
  '[STB] Get Firmware Version Failure',
  props<{ error: any }>()
);

export const getSerialNumber = createAction('[STB] Get Serial Number');

export const getSerialNumberSuccess = createAction(
  '[STB] Get Serial Number Success',
  props<{ serialNumber: string }>()
);

export const getSerialNumberFailure = createAction(
  '[STB] Get Serial Number Failure',
  props<{ error: any }>()
);

export const getModelNumber = createAction('[STB] Get Model Number');

export const getModelNumberSuccess = createAction(
  '[STB] Get Model Number Success',
  props<{ modelNumber: string }>()
);

export const getModelNumberFailure = createAction(
  '[STB] Get Model Number Failure',
  props<{ error: any }>()
);

export const getPowerMode = createAction('[STB] Get Power Mode');

export const getPowerModeSuccess = createAction(
  '[STB] Get Power Mode Success',
  props<{ powerMode: string }>()
);

export const getPowerModeFailure = createAction(
  '[STB] Get Power Mode Failure',
  props<{ error: any }>()
);
